<template>
  <div class="page-box" ref="page">
    <a-spin :spinning="formLoading">
      <a-form-model
        ref="formInstance"
        :model="form"
        :rules="readonly ? {} : rules"
        layout="vertical"
        @keydown.enter.native="submit"
      >
        <a-form-model-item label="活动名称：" prop="actName">
          <a-input
            v-if="!readonly"
            v-model="form.actName"
            allowClear
            placeholder="活动名称"
            style="width: 300px;"
          />
          <div v-else>{{form.actName}}</div>
        </a-form-model-item>
        <a-form-model-item label="发行量：" prop="sendNum">
          <a-input
            v-if="!readonly"
            v-model="form.sendNum"
            placeholder="发行量"
            style="width: 140px"
            suffix="次"
          />
          <div v-else>{{form.sendNum}}次</div>
        </a-form-model-item>
        <a-form-model-item label="活动时间：">
          <template v-if="!readonly">
            <a-date-picker
              v-model="form.startTime"
              :disabled-date="disabledStartDate"
              format="YYYY-MM-DD HH:mm:ss"
              placeholder="开始时间"
              @change="changeDate"
              style="margin-right: 5px;"
            />
            至
            <a-date-picker
              v-model="form.endTime"
              :disabled-date="disabledEndDate"
              format="YYYY-MM-DD HH:mm:ss"
              placeholder="结束时间"
              @change="changeDate"
              style="margin-left: 5px;"
            />
          </template>
          <div v-else>
            <span v-if="form.startTime && form.endTime">{{form.startTime}} 至 {{form.endTime}}</span>
            <span v-else-if="form.startTime">{{form.startTime}}起</span>
            <span v-else-if="form.endTime">{{form.endTime}}之前</span>
            <span v-else>永久</span>
          </div>
        </a-form-model-item>
        <a-form-model-item label="状态：" prop="status">
          <a-radio-group
            v-if="!readonly"
            v-model="form.status"
            placeholder="请选择"
            style="width: 140px"
            :options="[
              { value: 1, label: '有效' },
              { value: 2, label: '无效' },
            ]"
          ></a-radio-group>
          <div v-else>{{({
            1: '有效',
            2: '无效',
          })[form.status]}}</div>
        </a-form-model-item>
      </a-form-model>
    </a-spin>

    <RuleTable
      :rules="form.rules"
      ref="ruleTable"
      @add="addRule"
      @delete="deleteRule"
      #default="{selected, save}"
      :readonly="readonly"
    >
      <CouponTable
        :selected="selected"
        :save="save"
        is-setter
      ></CouponTable>
    </RuleTable>

    <a-row type="flex" align="middle" style="padding-top: 20px;" justify="center" v-if='!readonly'>
      <a-button type="primary" @click="submit" :loading="saveBtnLoading">保存</a-button>
      <a-button style="margin-left: 10px;" @click="closeCurrPage">取消</a-button>
    </a-row>
    <a-row type="flex" align="middle" style="padding-top: 20px;" justify="center" v-else>
      <a-button @click="closeCurrPage">关闭</a-button>
    </a-row>
  </div>
</template>

<script>
import { onMounted, reactive, ref, watch } from '@vue/composition-api'
import { activity } from '@/api'
import { SHOPID } from '@/constants'
import { getSession } from '@/utils/session'
import { message } from 'ant-design-vue'
import moment from 'moment'
import RuleTable from './RuleTable'
import CouponTable from './PageCashCouponCoupons.vue'
import { closePage } from '@/utils/tools'

export default {
  name: 'PageCashCouponForm',
  components: {
    CouponTable,
    RuleTable,
  },
  props: {
    readonly: String,
    id: String,
  },
  setup (props, { emit, root }) {
    const form = reactive({
      actName: '',
      startTime: '',
      endTime: '',
      sendNum: '',
      status: 1,
      shopId: getSession(SHOPID),
      rules: [
        createRule()
      ]
    })
    const saveBtnLoading = ref(false)
    const formInstance = ref(null)
    async function submit () {
      const valid = await formInstance.value.validate()
      if (!valid) return
      // 校验规则
      if (form.rules.length === 0) return message.error('至少添加一条规则')
      const ruleExp = /^(\[|\()([0-9]+)(,\s*)([0-9]*|∞|无穷.*|无限.*|无尽.*)(\]|\))$/
      for (let i = 0; i < form.rules.length; i++) {
        const r = form.rules[i]
        if (!r.ruleName || !r.ruleName.trim()) return message.error(`${i}：请检查规则名称`)
        if (!r.ruleCondition || !ruleExp.test(r.ruleCondition)) return message.error(`${i}：费用区间不存在或格式不正确`)
        const m = ruleExp.exec(r.ruleCondition)
        let f = m[2]
        let b = m[4]
        if (b === '' || Number.isNaN(+b)) b = Infinity
        if (+f >= +b) return message.error(`${i}：无效的费用区间`)
        if (r.cashCoupons.length === 0) return message.error(`${i}：至少要设置一张代金券`)
      }
      saveBtnLoading.value = true
      const { code, msg } = await activity.saveCashCouponActivity({
        shopId: getSession(SHOPID),
        cashCouponActId: props.id,
        ...form,
        rules: form.rules.map(x => ({
          ...x,
          ruleCondition: x.ruleCondition.replace(ruleExp, (m, p1, p2, p3, p4, p5) => {
            return p1 + p2 + ',' + (p4 === '' || Number.isNaN(+p4) ? '∞' : p4) + p5
          }),
          list: x.cashCoupons.map(y => ({
            cashCouponId: y.id,
            count: y.num,
          }))
        }))
      })
      saveBtnLoading.value = false
      if (code === '00000') {
        message.success('保存成功')
        root.$bus.$emit('PageCashCoupon:refresh')
        closeCurrPage()
      } else {
        message.error(msg || '服务错误，请稍后再试')
      }
    }
    function closeCurrPage () {
      closePage('/activity/cash_coupon')
    }
    const rules = {
      actName: [
        { required: true, message: '请输入活动名称', trigger: 'blur' },
        { max: 32, message: '您可以尝试一下简短的名称', trigger: 'blur' },
      ],
      sendNum: [
        { required: true, message: '请输入发行量', trigger: 'blur' },
        { pattern: /^[1-9][0-9]*$/, message: '格式不正确', trigger: 'blur' },
      ],
      activityTime: [
        {
          validator: (rule, value, callback) => {
            if (!form.startTime || !form.endTime) {
              callback(new Error('请选择活动时间'))
            } else {
              callback()
            }
          }
        }
      ],
    }
    watch(() => props.id, () => {
      formInstance.value.resetFields()
    })

    const formLoading = ref(false)
    onMounted(getDetail)
    async function getDetail () {
      if (props.id) {
        formLoading.value = true
        const { data } = await activity.getCashCouponActivity(props.id)
        formLoading.value = false
        if (data.actName) form.actName = data.actName
        if (data.startTime) form.startTime = moment(data.startTime)
        if (data.endTime) form.endTime = moment(data.endTime)
        if (data.sendNum) form.sendNum = data.sendNum
        if (data.status) form.status = data.status
        if (data.rules && data.rules.length) {
          form.rules = data.rules.map(r => ({
            ...r,
            cashCoupons: r.list.map(y => ({
              id: y.cashCouponId,
              num: y.count,
              name: y.cashCouponName,
              amount: y.couponAmount,
            }))
          }))
        }
      }
    }

    function disabledStartDate (startValue) {
      if (!form.endTime) return false
      return startValue.isBefore(form.endTime)
    }

    function disabledEndDate (endValue) {
      const nowValue = moment()
      const gtNowFlag = endValue.isAfter(nowValue)
      const gtStartFlag = form.startTime ? endValue.isAfter(form.startTime) : true
      return !(gtStartFlag && gtNowFlag)
    }

    function changeDate () {
      formInstance.validateField('activityTime')
    }

    const ruleTable = ref(null)
    function deleteRule (id) {
      const foundIndex = form.rules.findIndex(x => x.cashCouponRuleId === id)
      form.rules.splice(foundIndex, 1)
    }
    function addRule () {
      form.rules.push(createRule())
    }

    function createRule () {
      return {
        cashCouponRuleId: createRuleId(),
        ruleName: '',
        ruleCondition: '',
        cashCoupons: []
      }
    }

    function createRuleId () {
      return Date.now() + '_client_gen'
    }

    return {
      submit,
      form,
      rules,
      saveBtnLoading,
      formInstance,
      formLoading,
      disabledStartDate,
      disabledEndDate,
      changeDate,
      ruleTable,
      addRule,
      deleteRule,
      closeCurrPage,
    }
  }
}
</script>
