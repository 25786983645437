<template>
  <div>
    <div style="padding-bottom: 15px;">
      发放规则
      <!-- <a-button size="small" style="margin-left: 10px;" @click="$emit('add')">添加</a-button> -->
    </div>
    <a-table
      bordered
      :columns="columns"
      :dataSource="rules"
      :pagination="false"
      :rowKey="record => record.cashCouponRuleId"
    >
      <template #index="text, record, index">
        {{index}}
      </template>

      <template #ruleName="record">
        <a-input v-if="!readonly" v-model="record.ruleName" placeholder="规则名称"></a-input>
        <span v-else>{{record.ruleName}}</span>
      </template>

      <template #ruleCondition="record">
        <a-input
          v-if="!readonly"
          v-model="record.ruleCondition"
          placeholder="例：[0,600)"
        >
          <a-tooltip slot="suffix" title="[600,)或[600,∞)或[600,无穷)都可以表示大于等于600">
            <a-icon type="question-circle" style="color: rgba(0,0,0,.45)" />
          </a-tooltip>
        </a-input>
        <span v-else>{{record.ruleCondition}}</span>
      </template>

      <template #cashCoupons="record">
        <div class="wrap-columns">
          <a-popover placement="left" trigger="click" title="选择优惠券">
            <a-button v-if="!readonly" style="color: #f35543" type="link" size="small">设置</a-button>
            <template #content>
              <slot
                :selected="record.cashCoupons"
                :save="saveCoupon(record)"
              />
            </template>
          </a-popover>
          <CashCoupon
            v-for="c in record.cashCoupons"
            :key="c.cashCouponId"
            :data="c"
            @delete="deleteCoupon(record, $event)"
            :readonly="readonly"
          ></CashCoupon>
        </div>
      </template>

      <template #operation="record">
        <a-button
          type="link"
          @click="$emit('delete', record.cashCouponRuleId)"
        >删除</a-button>
      </template>

      <template v-if="!readonly" #footer>
        <div
          @click="$emit('add')"
          style="cursor: pointer; text-align: center; font-size: 12px; color: #999;"
        >
          点击此处添加一条新的规则
        </div>
      </template>
    </a-table>
  </div>
</template>

<script>
import CashCoupon from './CashCoupon'

export default {
  name: 'RuleTable',
  emits: ['add', 'delete'],
  components: {
    CashCoupon,
  },
  props: {
    rules: Array,
    readonly: String,
  },
  setup (props, { emit }) {
    function saveCoupon (rule) {
      return ({ id, num, name, amount }) => {
        if (num === 0) {
          const index = rule.cashCoupons.findIndex(x => x.id === id)
          if (index > -1) rule.cashCoupons.splice(index, 1)
        } else {
          const found = rule.cashCoupons.find(x => x.id === id)
          if (found) {
            found.num = num
          } else {
            rule.cashCoupons.push({
              id, num, name, amount
            })
          }
        }
      }
    }

    function deleteCoupon (rule, id) {
      const index = rule.cashCoupons.findIndex(x => x.id === id)
      if (index > -1) rule.cashCoupons.splice(index, 1)
    }

    return {
      columns: [
        {
          title: '#',
          width: '50px',
          scopedSlots: {
            customRender: 'index',
          },
        },
        {
          title: '规则名称',
          width: '160px',
          scopedSlots: {
            customRender: 'ruleName'
          }
        },
        {
          title: '费用区间',
          width: '240px',
          scopedSlots: {
            customRender: 'ruleCondition'
          }
        },
        {
          title: '代金券组合',
          scopedSlots: {
            customRender: 'cashCoupons'
          }
        },
        ...(!props.readonly ? [{
          title: '操作',
          width: '90px',
          align: 'center',
          scopedSlots: {
            customRender: 'operation'
          }
        }] : [])
      ],
      saveCoupon,
      deleteCoupon,
    }
  }
}
</script>

<style scoped>
.wrap-columns {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: -2.5px 0;
}
</style>
