<template>
  <span class="coupon-item" :style="{'max-width': readonly ? '100px' : '160px'}">
    <a-tooltip class="title">
      <template #title>
        {{data.name}}
      </template>
      {{data.amount}} × {{data.num}}
    </a-tooltip>
    <template v-if="!readonly">
      <a-button class="btn" @click="data.num > 1 && data.num--" size="small" icon="arrow-down"></a-button>
      <a-button class="btn" @click="data.num++" size="small" icon="arrow-up"></a-button>
      <a-button class="btn" @click="$emit('delete', data.id)" size="small" icon="close"></a-button>
    </template>
  </span>
</template>

<script>
export default {
  name: 'CashCoupon',
  props: {
    data: Object,
    readonly: String,
  },
  emits: ['delete'],
  setup (props) {
    return {

    }
  }
}
</script>

<style scoped>
.coupon-item {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  border: 1px solid #eee;
  border-radius: 5px;
  margin-left: 10px;
  margin-bottom: 2.5px;
  margin-top: 2.5px;
  flex: 1 0 auto;
  background: #f35543;
  color: #fff;
  font-size: 12px;
}
.btn {
  margin-left: 5px;
  background: #f35543;
  color: #fff;
  border-color: #fff;
  font-size: inherit;
  width: 20px;
  height: 20px;
}
.title {
  flex: 1 0 auto;
}
</style>
