<template>
  <div :class="{'page-box': !isSetter}" :style="isSetter ? {width: '400px', height: '634px', 'user-select': 'none'} : null">
    <base-screen v-if="!isSetter">
      <a-form-model ref="formRef" layout="inline" :model="params" @submit.native.prevent @keydown.native.enter="handleSearch">
        <a-form-model-item label="代金券名称" prop="cashCouponName">
          <a-input v-model="params.cashCouponName" placeholder="请输入" />
        </a-form-model-item>
        <a-form-model-item>
          <a-button type="primary" @click="handleSearch">查询</a-button>
          <a-button @click="handleReset">重置</a-button>
        </a-form-model-item>
      </a-form-model>
    </base-screen>
    <a-form-model v-else ref="formRef" layout="inline" :model="params" @submit.native.prevent @keydown.native.enter="handleSearch">
      <a-form-model-item label="代金券名称" prop="cashCouponName">
        <a-input v-model="params.cashCouponName" placeholder="请输入" />
      </a-form-model-item>
      <a-form-model-item>
        <a-button type="primary" @click="handleSearch">查询</a-button>
      </a-form-model-item>
    </a-form-model>
    <div v-if="!isSetter" class="btn-box">
      <a-button type="primary" @click="openDialogOfCreateCoupon">新建代金券</a-button>
    </div>
    <base-table
      bordered
      :columns="columns"
      :dataSource="tableData"
      :pagination="pagination"
      :rowKey="record => record.cashCouponId"
      :loading="tableLoading"
      :size="isSetter ? 'small' : 'default'"
      @change="handleTableChange"
    >
      <template v-if="!isSetter" #cashCouponName="text,record">
        <a-button
          type="link"
          @click="openDialogOfCreateCoupon(record, '1')"
        >{{text}}</a-button>
      </template>

      <template v-if="isSetter" #operation="record">
        <a-input style="width: 140px;" :value="resolveNum(record)" @change="setNum(record, $event.target.value)">
          <template #addonBefore>
            <a-icon @click.native="stepNum(record, -1)" type="minus" />
          </template>
          <template #addonAfter>
            <a-icon @click.native="stepNum(record, +1)" type="plus" />
          </template>
        </a-input>
      </template>
      <template v-else #operation="record">
        <a-button
          type="link"
          @click="openDialogOfCreateCoupon(record)"
        >编辑</a-button>
        <a-popconfirm
          placement="topRight"
          title="确定要删除？"
          ok-text="确定"
          cancel-text="取消"
          @confirm="deleteItem(record)"
        >
          <a-button
            :loading="deleteLoadingItem === record.cashCouponId"
            type="link"
          >删除</a-button>
        </a-popconfirm>
      </template>
    </base-table>

    <a-modal
      v-model="dialogVisibleOfCreateCoupon"
      title="代金券"
      :footer="null"
    >
      <CouponEditor
        @close="dialogVisibleOfCreateCoupon = false"
        @success="getTableData"
        :data="openedRecord"
        :readonly="recordReadonlyFlag"
      ></CouponEditor>
    </a-modal>
  </div>
</template>

<script>
import { reactive, ref, toRefs, onMounted, onUnmounted } from '@vue/composition-api'
import { activity } from '@/api'
import { SHOPID } from '@/constants'
import { getSession } from '@/utils/session'
import CouponEditor from './CouponEditor'
import { message, Modal } from 'ant-design-vue'

const columns = [
  {
    title: '代金券名称',
    dataIndex: 'cashCouponName',
    scopedSlots: { customRender: 'cashCouponName' }
  },
  {
    title: '面额',
    dataIndex: 'couponAmount',
  },
  {
    title: '有效期天数',
    dataIndex: 'validDay',
  },
  {
    title: '操作',
    scopedSlots: { customRender: 'operation' }
  },
]

export default {
  name: 'PageCashCouponCoupons',
  props: {
    isSetter: Boolean,
    selected: Array,
    save: Function
  },
  components: {
    CouponEditor,
  },
  setup (props, { root }) {
    const state = reactive({
      params: {
        shopId: getSession(SHOPID),
        productName: '',
      },
      formRef: null,
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0
      },
      tableData: [],
      tableLoading: false
    })
    const callback = () => { getTableData() }
    onMounted(() => {
      getTableData()
    })
    async function getTableData () {
      state.tableLoading = true
      let { code, data, page, msg } = await activity.getCashCouponPage({
        current: state.pagination.current,
        size: state.pagination.pageSize,
        shopId: getSession(SHOPID),
        ...state.params
      })
      state.tableLoading = false
      if (code === '00000') {
        state.tableData = data
        state.pagination.current = page.pageNo
        state.pagination.pageSize = page.pageSize
        state.pagination.total = page.total
      } else {
        root.$message.error(msg || '获取数据失败')
      }
    }
    function handleTableChange ({ current }) {
      state.pagination.current = current
      getTableData()
    }

    function handleSearch () {
      state.pagination.current = 1
      getTableData()
    }

    function handleReset () {
      state.formRef.resetFields()
      handleSearch()
    }

    const dialogVisibleOfCreateCoupon = ref(false)
    const openedRecord = ref(null)
    const recordReadonlyFlag = ref(false)
    function openDialogOfCreateCoupon (record, readonly) {
      dialogVisibleOfCreateCoupon.value = true
      recordReadonlyFlag.value = readonly || false
      openedRecord.value = record
    }

    const deleteLoadingItem = ref(null)
    async function deleteItem (record) {
      deleteLoadingItem.value = record.cashCouponId
      const { code, msg } = await activity.deleteCashCoupon(record.cashCouponId)
      deleteLoadingItem.value = null
      if (code === '00000') {
        message.success('删除成功')
        getTableData()
      } else {
        message.error('删除失败')
      }
    }

    function resolveNum (c) {
      const found = props.selected.find(x => x.id === c.cashCouponId)
      return found ? found.num : 0
    }

    function setNum (record, num) {
      props.save({
        id: record.cashCouponId,
        num,
        name: record.cashCouponName,
        amount: record.couponAmount,
      })
    }

    function stepNum (record, step) {
      const found = props.selected.find(x => x.id === record.cashCouponId)
      if (!(step === -1 && found && +found.num === 0)) setNum(record, found ? (+found.num + step) : 1)
    }

    return {
      columns,
      ...toRefs(state),
      handleReset,
      handleSearch,
      handleTableChange,
      getTableData,
      dialogVisibleOfCreateCoupon,
      openDialogOfCreateCoupon,
      openedRecord,
      recordReadonlyFlag,
      deleteItem,
      deleteLoadingItem,
      resolveNum,
      setNum,
      stepNum,
    }
  }
}
</script>

<style lang="less" scoped>
.dialog-foot {
  padding-top: 18px;
  margin-top: 18px;
  border-top: 1px solid #ddd;
}
::v-deep .ant-form-item {
  margin-bottom: 8px;
}
.role-name-box {
  display: flex;
  align-items: center;
  .official-flag {
    background: @main-color;
    color: #fff;
    padding: 4px;
    font-size: 12px;
    line-height: 12px;
    border-radius: 2px;
    margin-left: 6px;
    font-weight: bolder;
    flex-shrink: 0;
  }
}
.tips {
  font-size: 12px;
  color: #666;
  line-height: 24px;
}
.btn-box {
  margin-bottom: 18px;
  ::v-deep .ant-btn {
    margin-right: 12px;
    &:last-child {
      margin: 0;
    }
  }
}
</style>
