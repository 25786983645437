<template>
  <a-form-model
    ref="formInstance"
    :model="form"
    :rules="readonly ? {} : rules"
    :label-col="{ span: 5 }"
    :wrapper-col="{ span: 19 }"
    @keydown.enter.native="submit"
  >
    <a-form-model-item label="代金券名称：" prop="cashCouponName">
      <a-input
        v-if="!readonly"
        v-model="form.cashCouponName"
        allowClear
        placeholder="代金券名称"
      />
      <div v-else>{{form.cashCouponName}}</div>
    </a-form-model-item>
    <a-form-model-item label="面额：" prop="couponAmount">
      <a-input
        v-if="!readonly"
        v-model="form.couponAmount"
        placeholder="面额"
        style="width: 140px"
        suffix="元"
      />
      <div v-else>{{form.couponAmount}}</div>
    </a-form-model-item>
    <a-form-model-item label="有效期天数：" prop="validDay">
      <a-input
        v-if="!readonly"
        v-model="form.validDay"
        placeholder="有效期天数"
        style="width: 140px"
        suffix="天"
      />
      <div v-else>{{form.validDay}}</div>
    </a-form-model-item>
    <a-form-model-item label="序号：" prop="seqNum">
      <a-input
        v-if="!readonly"
        v-model="form.seqNum"
        allowClear
        style="width: 140px"
        placeholder="序号"
      />
      <div v-else>{{form.seqNum}}</div>
    </a-form-model-item>
    <a-form-model-item label="使用说明：" prop="remark">
      <a-input
        v-if="!readonly"
        v-model="form.remark"
        allowClear
        type="textarea"
        placeholder="使用说明"
      />
      <div v-else>{{form.remark}}</div>
    </a-form-model-item>

    <a-row type="flex" align="middle" justify="center" v-if='!readonly'>
      <a-button type="primary" @click="submit" :loading="saveBtnLoading">保存</a-button>
      <a-button style="margin-left: 10px;" @click="$emit('close')">取消</a-button>
    </a-row>
    <a-row type="flex" align="middle" justify="center" v-else>
      <a-button @click="$emit('close')">关闭</a-button>
    </a-row>
  </a-form-model>
</template>

<script>
import { reactive, ref, watch } from '@vue/composition-api'
import { activity } from '@/api'
import { SHOPID } from '@/constants'
import { getSession } from '@/utils/session'
import { message } from 'ant-design-vue'

export default {
  name: 'CouponEditor',
  props: {
    readonly: Boolean,
    data: null,
  },
  setup (props, { emit }) {
    const form = reactive({
      cashCouponName: '',
      couponAmount: '',
      validDay: '',
      couponType: '1',
      seqNum: '',
      remark: '',
    })
    const saveBtnLoading = ref(false)
    const formInstance = ref(null)
    async function submit () {
      const valid = await formInstance.value.validate()
      if (!valid) return
      saveBtnLoading.value = true
      const { code, msg } = await activity.createCashCoupon({
        cashCouponId: props.data.cashCouponId,
        shopId: getSession(SHOPID),
        ...form
      })
      saveBtnLoading.value = false
      if (code === '00000') {
        emit('success')
        emit('close')
        message.success('保存成功')
        setTimeout(() => {
          formInstance.value.resetFields()
        })
      } else {
        message.error(msg || '服务错误，请稍后再试')
      }
    }
    const rules = {
      cashCouponName: [
        { required: true, message: '请输入代金券名称', trigger: 'blur' },
        { max: 32, message: '您可以尝试一下简短的名称', trigger: 'blur' },
      ],
      couponAmount: [
        { required: true, message: '请输入代金券面额', trigger: 'blur' },
        { pattern: /^[1-9][0-9]*$/, message: '格式不正确', trigger: 'blur' },
      ],
      validDay: [
        { required: true, message: '请输入代金券有效期天数', trigger: 'blur' },
        { pattern: /^[1-9][0-9]*$/, message: '格式不正确', trigger: 'blur' },
      ],
    }
    watch(() => props.data && props.data.cashCouponId, () => {
      formInstance.value.resetFields()
    })
    watch(() => props.data, (v) => {
      if (v) {
        for (const key in form) {
          if (v[key] != null) {
            form[key] = v[key]
          }
        }
      }
    }, { immediate: true })
    return {
      submit,
      form,
      rules,
      saveBtnLoading,
      formInstance,
    }
  }
}
</script>
